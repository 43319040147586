import React from "react"
import {Switch} from "react-router-dom"
import {PublicRoute} from "./layouts"

import Home from "./pages/home"
import Workgroups from "./pages/workgroup"
import Workgroup from "./pages/workgroup/detail"

class AppRouter extends React.Component {
    render() {
        const publicRouting = (
            <Switch>
                <PublicRoute path="/workgroup/:id" component={Workgroup} />
                <PublicRoute path="/workgroup" component={Workgroups} />
                <PublicRoute path="/" component={Home} />
            </Switch>
        )
        return publicRouting
    }
}

export default AppRouter
