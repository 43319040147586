import React from "react"
import PropTypes from "prop-types"
import {Link} from "react-router-dom"
import {Icon} from "antd"
import $ from "jquery"
import {imgSrc} from "../../modules"

import "./header.css"

class HeaderComponent extends React.Component {
    state = {
        searchActive: false,
        menuActive: false,
        searchText: "",
        active: "/"
    }

    toggleSearch = () => {
        const {searchActive} = this.state
        this.setState({searchActive: !searchActive})
        if (!searchActive) {
            this.search.focus()
        }
    }

    setText = (e) => {
        this.setState({searchText: e.target.value})
    }

    slideTo = (section) => {
        const {location} = this.props
        const {pathname} = location
        this.setState({active: section, menuActive: false})

        setTimeout(
            () => {
                const offsetDec = $(window).width() > 768 ? 50 : 20
                const offsetTop = $("#" + section).offset() && $("#" + section).offset().top ? $("#" + section).offset().top - offsetDec : 0
                $([document.documentElement, document.body]).animate(
                    {
                        scrollTop: section === "home" ? 0 : offsetTop
                    },
                    600
                )
            },
            pathname === "/" ? 0 : 400
        )
    }

    toggleMenu = () => {
        this.setState({menuActive: !this.state.menuActive})
    }

    componentDidMount = () => {
        $(window).scroll(() => {
            const top = $(window).scrollTop()
            if (top > 0 && !$("header").hasClass("sticky")) {
                $("header").addClass("sticky")
            } else if ($("header").hasClass("sticky") && top < 1) {
                $("header").removeClass("sticky")
            }
        })
    }

    render() {
        const {searchActive, searchText, active, menuActive} = this.state
        const {location, setLang, lang} = this.props
        const {pathname} = location
        return (
            <header className={pathname === "/" ? "" : "active"}>
                <div>
                    <nav>
                        <div>
                            <Link to="/" onClick={() => this.slideTo("home")}>
                                <img src={imgSrc("logo.svg", false)} alt="FAMOUS INFINITY CO.,LTD." />
                            </Link>
                        </div>
                        <div>
                            <ul id="main-menu" className={menuActive ? "active" : ""}>
                                <li className={pathname === "/" && active === "home" ? "active" : ""} onClick={() => this.slideTo("home")}>
                                    <Link to={pathname === "/" ? "/#" : "/"}>{lang === "en" ? "HOME" : "หน้าแรก"}</Link>
                                </li>
                                <li className={pathname === "/" && active === "about" ? "active" : ""} onClick={() => this.slideTo("about")}>
                                    <Link to="/#about">{lang === "en" ? "ABOUT US" : "เกี่ยวกับเรา"}</Link>
                                </li>
                                <li className={pathname === "/workgroup" ? "active" : ""} onClick={() => this.slideTo("workgroup-page")}>
                                    <Link to="/workgroup">{lang === "en" ? "WORK GROUP" : "กลุ่มงาน"}</Link>
                                </li>
                                <li className={pathname === "/" && active === "join" ? "active" : ""} onClick={() => this.slideTo("join")}>
                                    <Link to="/#join">{lang === "en" ? "JOIN US" : "ร่วมงานกับเรา"}</Link>
                                </li>
                                <li className={pathname === "/" && active === "contact" ? "active" : ""} onClick={() => this.slideTo("contact")}>
                                    <Link to="/#contact">{lang === "en" ? "CONTACT US" : "ติดต่อเรา"}</Link>
                                </li>
                            </ul>
                            <div id="lang">
                                <div>
                                    <img src={imgSrc("language.svg", false)} alt="language" />
                                    <b>{lang === "th" ? "ไทย" : "EN"}</b>
                                    <Icon type="down" />
                                </div>
                                <ul>
                                    <li>
                                        <button onClick={() => setLang("en")}>
                                            <img src={imgSrc("flag-en.png", false)} alt="EN" />
                                            <span>EN</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button onClick={() => setLang("th")}>
                                            <img src={imgSrc("flag-th.png", false)} alt="TH" />
                                            <span>ไทย</span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div id="search" className={searchActive ? "active" : ""}>
                                <form method="GET" id="search-form" action="http://www.google.com/search" target="_blank" onSubmit={this.toggleSearch}>
                                    <input
                                        type="search"
                                        ref={(input) => {
                                            this.search = input
                                        }}
                                        name="q"
                                        onChange={this.setText}
                                        value={searchText}
                                        placeholder={lang === "en" ? "Search..." : "ค้นหา..."}
                                    />
                                    <input type="hidden" name="sitesearch" value="famous-infinity.com" />
                                    {searchActive && searchText !== "" && (
                                        <button type="submit">
                                            <img src={imgSrc("search.svg", false)} alt="Search" />
                                        </button>
                                    )}
                                    <img src={imgSrc("search-01.svg", false)} alt="Search" onClick={this.toggleSearch} />
                                    {searchActive && searchText === "" && <Icon type="close-circle" onClick={this.toggleSearch} />}
                                </form>
                            </div>
                            {!menuActive && <Icon type="menu" id="menu-responsive" onClick={this.toggleMenu} />}
                            {menuActive && <Icon type="close-circle" id="menu-responsive" onClick={this.toggleMenu} />}
                        </div>
                    </nav>
                </div>
            </header>
        )
    }
}

HeaderComponent.propTypes = {
    lang: PropTypes.oneOf(["en", "th"]).isRequired,
    setLang: PropTypes.func.isRequired
}

export default HeaderComponent
