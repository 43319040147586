import React from "react"
import Header from "./header"
import Footer from "./footer"

class LayoutComponent extends React.Component {
    state = {lang: window.localStorage.getItem("lang") ? window.localStorage.getItem("lang") : "en", loading: true}

    setLang = (lang) => {
        this.setState({lang})
        window.localStorage.setItem("lang", lang)
    }

    componentDidMount = () => {
        this.setState({loading: false})
    }

    render() {
        const {lang, loading} = this.state
        const {props, rest, Component} = this.props
        return (
            <div className={loading ? "loading" : ""}>
                <Header {...props} {...rest} lang={lang} setLang={this.setLang} />
                <Component {...props} {...rest} lang={lang} />
                <Footer />
            </div>
        )
    }
}

export default LayoutComponent
