import React from "react"
import PropTypes from "prop-types"
import {Link} from "react-router-dom"
import {Tabs, Icon} from "antd"
import {imgSrc} from "../../modules"
import "./workgroup.css"

const TabPane = Tabs.TabPane

class WorkgroupComponent extends React.Component {
    state = {current: 0}

    renderWorkgroup = (works) => {
        const {max, lang} = this.props
        const limit = max ? max : 8
        const workList = works.map((value, key) => {
            return (
                key < limit && (
                    <div key={value.id}>
                        <Link to={"/workgroup/" + value.id}>
                            <img src={imgSrc(value.image)} alt={value.name_en || value.name_th} />
                            <div>
                                <h3>{lang === "en" ? value.name_en : value.name_th}</h3>
                                <p>
                                    <Icon type="minus" />
                                    <span>{lang === "en" ? value.type_en : value.type_th}</span>
                                    <Icon type="minus" />
                                </p>
                            </div>
                        </Link>
                    </div>
                )
            )
        })
        return <div className="works">{workList}</div>
    }

    handleClick = (e) => {
        this.setState({current: e})
    }

    render() {
        let defaultActiveKey = 0
        const {current} = this.state
        const {data, lang, handleLoad, search} = this.props
        const s = decodeURI(search).substr(3)
        if (!data) return null
        
        data.forEach((value, key) => {
            if (value.name_en === s) {
                defaultActiveKey = key
            }
        })

        return (
            <div id="workgroups">
                <div>
                    <Tabs defaultActiveKey={defaultActiveKey.toString()} onTabClick={this.handleClick}>
                        {data.map((value) => {
                            return (
                                <TabPane key={value.id} tab={lang === "en" ? value.name_en : value.name_th}>
                                    {this.renderWorkgroup(value.items)}
                                </TabPane>
                            )
                        })}
                    </Tabs>
                </div>
                {data[current].loadmore && (
                    <div>
                        <button type="button" className="load-more" onClick={handleLoad}>
                            <span>{lang === "en" ? "LOAD MORE" : "โหลดเพิ่มเติม"}</span> <img src="/img/right-arrow-1.svg" alt="right arrow" />
                        </button>
                    </div>
                )}
            </div>
        )
    }
}

WorkgroupComponent.propTypes = {
    max: PropTypes.number,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name_en: PropTypes.string,
            name_th: PropTypes.string,
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string,
                    name_en: PropTypes.string,
                    name_th: PropTypes.string,
                    image: PropTypes.string
                })
            )
        })
    ).isRequired,
    lang: PropTypes.oneOf(["en", "th"]).isRequired,
    handleLoad: PropTypes.func,
    search: PropTypes.string
}

export default WorkgroupComponent
