import React from "react"
import PropTypes from "prop-types"
import {Collapse} from "antd"
import "./joinus.css"

const Panel = Collapse.Panel

class JoinUsComponent extends React.Component {
    render() {
        const {data, lang, email} = this.props
        return (
            <div id="joinus">
                <Collapse defaultActiveKey={["0"]} accordion>
                    {data.map((value, key) => {
                        const topic = lang === "en" ? value.name_en : value.name_th
                        const applyMsg = lang === "en" ? "Apply for a position of " : "สมัครงานตำแหน่ง "
                        return (
                            <Panel header={topic} key={key}>
                                <div>
                                    <div dangerouslySetInnerHTML={{__html: lang === "en" ? value.detail_en : value.detail_th}} />
                                    <a href={"mailto:" + email + "?subject=" + applyMsg + topic} className="btn-apply">
                                        <span>{lang === "en" ? "Apply Now" : "ลงทะเบียน"}</span>
                                    </a>
                                </div>
                            </Panel>
                        )
                    })}
                </Collapse>
            </div>
        )
    }
}

JoinUsComponent.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name_en: PropTypes.string,
            name_th: PropTypes.string,
            detail_en: PropTypes.string,
            detail_th: PropTypes.string
        })
    ).isRequired,
    lang: PropTypes.oneOf(["en", "th"]).isRequired,
    email: PropTypes.string.isRequired
}

export default JoinUsComponent
