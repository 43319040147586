import React from "react"
import PropTypes from "prop-types"
import Map from "./map"
import {imgSrc} from "../../../modules"
import "./contact.css"

class ContactComponent extends React.Component {
    render() {
        const {data, lang} = this.props
        if (!data) return null
        const {name_en, name_th, address_en, address_th, email, phone, fax, graphic_map, google_map, google_map_link} = data
        return (
            <div id="contact">
                <div className="container">
                    <h2>{lang === "en" ? "CONTACT US" : "ติดต่อเรา"}</h2>
                    <div id="contact-channel">
                        <div>
                            <img src={imgSrc("phone.svg", false)} alt="Phone" />
                            <div>
                                <p>
                                    <b>{lang === "en" ? "Phone, Fax" : "เบอร์โทร, แฟกซ์"}</b>
                                </p>
                                <p>
                                    {lang === "en" ? "Phone" : "เบอร์โทร"}: <a href={"tel:" + phone.replace(/-|\s|_|\(|\)/g, "")}>{phone}</a>
                                </p>
                                <p>
                                    {lang === "en" ? "Fax" : "แฟกซ์"}: {fax}
                                </p>
                            </div>
                        </div>
                        <div>
                            <img src={imgSrc("mail.svg", false)} alt="mail" />
                            <div>
                                <p>
                                    <b>{lang === "en" ? "Email" : "อีเมล"}</b>
                                </p>
                                <p>
                                    <a href={"mailto:" + email}>{email}</a>
                                </p>
                            </div>
                        </div>
                        <div>
                            <img src={imgSrc("marker.svg", false)} alt="marker" />
                            <div>
                                <p>
                                    <b>{lang === "en" ? "Address" : "ที่อยู่"}</b>
                                </p>
                                <p>
                                    {lang === "en" ? name_en : name_th}
                                    <br />
                                    <span dangerouslySetInnerHTML={{__html: lang === "en" ? address_en : address_th}} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Map data={{graphic_map: graphic_map, google_map: google_map, google_map_link: google_map_link}} lang={lang} />
            </div>
        )
    }
}

ContactComponent.propTypes = {
    data: PropTypes.shape({
        name_en: PropTypes.string,
        name_th: PropTypes.string,
        address_en: PropTypes.string,
        address_th: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        fax: PropTypes.string,
        graphic_map: PropTypes.string,
        google_map: PropTypes.string,
        google_map_link: PropTypes.string
    }).isRequired,
    lang: PropTypes.oneOf(["en", "th"]).isRequired
}

export default ContactComponent
