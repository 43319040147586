import React from "react"
import PropTypes from "prop-types"
import $ from "jquery"
import {Modal} from "antd"
import {imgSrc} from "../../../modules"
import "./contact.css"

class MapComponent extends React.Component {
    state = {visible: false, width: 800, iframeHeight: false}

    showModal = () => {
        this.setState({
            visible: true
        })
    }

    handleOk = (e) => {
        this.setState({
            visible: false
        })
    }

    handleCancel = (e) => {
        this.setState({
            visible: false
        })
    }

    componentDidMount = () => {
        const w = window.innerWidth
        let width = 350
        if (w > 1800) {
            width = 1200
        } else if (w > 1600) {
            width = 1200
        } else if (w > 991) {
            width = 800
        } else if (w > 768) {
            width = 600
        }
        this.setState({width})

        $("#graphicMap").on("load", () => {
            this.setState({iframeHeight: $("#graphicMap").height()})
        })
    }
    render() {
        const {data, lang} = this.props
        const {visible, width, iframeHeight} = this.state
        if (!data) return null
        const {graphic_map, google_map, google_map_link} = data
        return (
            <div id="map">
                <div id="map-info">
                    <div onClick={this.showModal}>
                        <img src={imgSrc(graphic_map)} id="graphicMap" alt="Map" />
                    </div>
                    <div>{iframeHeight && google_map && <iframe title="Google map" src={google_map} width="100%" height={iframeHeight} frameBorder="0" allowFullScreen />}</div>
                </div>
                <div id="viewmap">
                    {google_map_link && (
                        <a href={google_map_link} target="_blank" rel="noopener noreferrer">
                            <span>{lang === "en" ? "VIEW GOOGLE MAP" : "ดูแผนที่"}</span> <img src={imgSrc("right-arrow.svg", false)} alt="right arrow" />
                        </a>
                    )}
                </div>
                <Modal className="mapGraphic" title={false} visible={visible} onOk={this.handleOk} onCancel={this.handleCancel} footer={false} width={width}>
                    <img src={imgSrc(graphic_map)} alt="Map" onClick={this.showModal} />
                </Modal>
            </div>
        )
    }
}

MapComponent.propTypes = {
    data: PropTypes.shape({
        graphic_map: PropTypes.string,
        google_map: PropTypes.string,
        google_map_link: PropTypes.string
    }).isRequired,
    lang: PropTypes.oneOf(["en", "th"]).isRequired
}

export default MapComponent
