import React from "react"
import ReactDOM from "react-dom"
import {HashRouter} from "react-router-dom"
import createHistory from "history/createBrowserHistory"
import $ from "jquery"

import AppRouter from "./Router"

import "antd/dist/antd.min.css"
import "./assets/css/index.css"

const history = createHistory()
history.listen((loc) => {
    const {pathname, hash} = loc
    console.log('has', hash)
    const reg = /#en|#\/en|#th|#\/th|#\/workgroup\/en|#\/workgroup\/th|#\/workgroup\/#en|#\/workgroup\/#th/
    if (!reg.test(hash)) {
        $("#root").css({opacity: 0.5, filter: "grayscale(100%)"})
        setTimeout(() => {
            $("#root")
                .animate({
                    opacity: 1
                })
                .css({filter: "none"})
        }, 600)
    }
    if (pathname !== "/") {
        window.scrollTo(0, 0)
    }
})

const render = (Component) => {
    return ReactDOM.render(
        <HashRouter history={history}>
            <Component />
        </HashRouter>,
        document.getElementById("root")
    )
}

render(AppRouter)
