import React from "react"
import axios from "axios"
import {imgSrc} from "../../modules"
import "./footer.css"

class FooterComponent extends React.Component {
    state = {data: false}

    componentDidMount = () => {
        const me = this
        const {location} = window
        const apiLink = location.hostname === "localhost" ? "http://localhost:9000" : "http://" + location.hostname + "/server"
        axios
            .get(apiLink + "/contact.php")
            .then(function(response) {
                if (response.status === 200) {
                    me.setState({data: response.data})
                }
            })
            .catch(function(error) {
                console.log(error)
            })
    }

    render() {
        const {data} = this.state
        const {facebook, pinterest, gplus, instagram} = data
        return (
            <footer>
                <div className="container">
                    <div id="footer-content">
                        <div>
                            <p>© 2019 All Right Reserved.</p>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <a href={facebook} target="_blank" rel="noopener noreferrer">
                                        <img src={imgSrc("facebook.svg", false)} alt="facebook" />
                                    </a>
                                </li>
                                <li>
                                    <a href={instagram} target="_blank" rel="noopener noreferrer">
                                        <img src={imgSrc("instagram.svg", false)} alt="instagram" />
                                    </a>
                                </li>
                                <li>
                                    <a href={gplus} target="_blank" rel="noopener noreferrer">
                                        <img src={imgSrc("google-plus.svg", false)} alt="google-plus" />
                                    </a>
                                </li>
                                <li>
                                    <a href={pinterest} target="_blank" rel="noopener noreferrer">
                                        <img src={imgSrc("pinterest.svg", false)} alt="pinterest" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default FooterComponent
