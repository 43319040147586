// import React from "react"
// import {Route} from "react-router-dom"
// import Header from "./header"
// import Footer from "./footer"

// export const PublicRoute = ({component: Component, ...rest}) => (
//     <Route
//         {...rest}
//         render={(props) => {
//             return (
//                 <React.Fragment>
//                     <Header {...props} {...rest} />
//                     <main>
//                         <Component {...props} {...rest} />
//                     </main>
//                     <Footer />
//                 </React.Fragment>
//             )
//         }}
//     />
// )

import React from "react"
import {Route} from "react-router-dom"
import Layout from "./layout"

export const PublicRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={(props) => {
            return <Layout props={props} rest={rest} Component={Component} />
        }}
    />
)

export default PublicRoute
