import React from "react"
import PropTypes from "prop-types"
import {Link} from "react-router-dom"
import Workgroup from "../../../components/workgroup"
import "./workgroup.css"

class WorkgroupComponent extends React.Component {
    render() {
        const {data, lang} = this.props
        const {name_en, name_th, detail_en, detail_th, types} = data
        return (
            <div id="workgroup">
                <h2>{lang === "en" ? name_en : name_th}</h2>
                <p dangerouslySetInnerHTML={{__html: lang === "en" ? detail_en : detail_th}} />
                <Workgroup max={9} data={types} lang={lang} />
                <div id="more-workgroup">
                    <Link to="/workgroup">
                        <span>{lang === "en" ? "MORE WORK GROUP" : "ดูทั้งหมด"}</span> <img src="/img/right-arrow.svg" alt="right arrow" />
                    </Link>
                </div>
            </div>
        )
    }
}

WorkgroupComponent.propTypes = {
    data: PropTypes.shape({
        name_en: PropTypes.string,
        name_th: PropTypes.string,
        detail_en: PropTypes.string,
        detail_th: PropTypes.string,
        types: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                name_en: PropTypes.string,
                name_th: PropTypes.string,
                items: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.string,
                        name_en: PropTypes.string,
                        name_th: PropTypes.string,
                        image: PropTypes.string
                    })
                )
            })
        )
    }).isRequired,
    lang: PropTypes.oneOf(["en", "th"]).isRequired
}

export default WorkgroupComponent
