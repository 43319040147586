import React from "react"
import PropTypes from "prop-types"
import {Modal} from "antd"
import $ from "jquery"
import {imgSrc} from "../../../modules"
import "./home.css"

class HomeComponent extends React.Component {
    state = {visible: false}

    showModal = () => {
        this.setState({
            visible: true
        })
    }

    handleOk = (e) => {
        this.setState({
            visible: false
        })
    }

    handleCancel = (e) => {
        this.setState({
            visible: false
        })
    }

    slideTo = () => {
        $([document.documentElement, document.body]).animate(
            {
                scrollTop: $("#about").offset().top - 50
            },
            500
        )
    }

    youtubeParser = (url) => {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
        const match = url.match(regExp)
        return match && match[7].length === 11 ? match[7] : false
    }

    render() {
        const {visible} = this.state
        const {data, lang} = this.props
        if (!data) return null
        const {description_en, description_th, name_en, name_th, image, video} = data
        const videoCode = this.youtubeParser(video)

        return (
            <div id="background" style={{backgroundImage: "url('" + imgSrc(image) + "')"}}>
                <div id="bgContent">
                    <p>
                        <strong>{lang === "en" ? name_en : name_th}</strong>
                    </p>
                    <p>
                        <b>{lang === "en" ? description_en : description_th}</b>
                    </p>
                    {video && video !== "" && <img src={imgSrc("play-button.svg", false)} alt="Play" onClick={this.showModal} />}
                </div>
                <img src={imgSrc("down.svg", false)} alt="V" id="mouse-down" onClick={this.slideTo} />
                {visible && (
                    <Modal className="modal-video" title={false} visible={visible} onOk={this.handleOk} onCancel={this.handleCancel} footer={false} width={900}>
                        <iframe title="Famous infinity., Ltd." width="900" height="506" src={"https://www.youtube.com/embed/" + videoCode + "?autoplay=1&loop=1&playlist=" + videoCode} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
                    </Modal>
                )}
            </div>
        )
    }
}

HomeComponent.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.string,
        name_en: PropTypes.string,
        name_th: PropTypes.string,
        description_en: PropTypes.string,
        description_th: PropTypes.string,
        image: PropTypes.string,
        video: PropTypes.string
    }).isRequired,
    lang: PropTypes.string.isRequired
}

export default HomeComponent
