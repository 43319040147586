import React from "react"
import PropTypes from "prop-types"
import JoinUs from "../../../components/joinus"
import "./joinus.css"

class JoinUsComponent extends React.Component {
    render() {
        const {lang, data, email} = this.props
        return (
            <div id="join">
                <div className="container">
                    <h2>{lang === "en" ? "JOIN US" : "ร่วมงานกับเรา"}</h2>
                    <JoinUs lang={lang} data={data} email={email} />
                </div>
            </div>
        )
    }
}

JoinUsComponent.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name_en: PropTypes.string,
            name_th: PropTypes.string,
            detail_en: PropTypes.string,
            detail_th: PropTypes.string
        })
    ).isRequired,
    lang: PropTypes.oneOf(["en", "th"]).isRequired,
    email: PropTypes.string.isRequired
}

export default JoinUsComponent
