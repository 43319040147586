import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import HomeData from "./home"
import About from "./about"
import Workgroup from "./workgroup"
import Joinus from "./joinus"
import Contact from "./contact"
import {apiUrl} from "../../modules"

class Home extends React.Component {
    state = {data: false}

    componentDidMount = () => {
        const me = this
        axios
            .get(apiUrl())
            .then(function(response) {
                if (response.status === 200) {
                    console.log('data', response.data)
                    me.setState({data: response.data})
                }
            })
            .catch(function(error) {
                console.log(error)
            })
    }
    render() {
        const {data} = this.state
        const {lang} = this.props
        if (!data) return null
        return (
            <React.Fragment>
                <main>
                    {data.home && <HomeData data={data.home} lang={lang} />}
                    {data.about && <About lang={lang} data={{about: data.about, service: data.service}} />}
                    {data.work && <Workgroup lang={lang} data={data.work} />}
                    {data.job && <Joinus lang={lang} data={data.job} email={data.contact.email} />}
                    {data.contact && <Contact lang={lang} data={data.contact} />}
                </main>
            </React.Fragment>
        )
    }
}

Home.propTypes = {
    lang: PropTypes.oneOf(["en", "th"]).isRequired
}

export default Home
