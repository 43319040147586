import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import WorkList from "../../components/workgroup"
import {apiUrl} from "../../modules"
import "./workgroup.css"

class Workgroup extends React.Component {
    state = {types: false, data: false, perpage: 9, page: 0}

    handleLoad = () => {
        const {data} = this.state
        this.pagination(data)
    }

    pagination = (data) => {
        const {perpage, page} = this.state
        const currentPage = page + 1
        const limit = perpage * currentPage
        const types = data.types.map((value) => {
            const n = value.items.length
            const newItems = value.items.slice(0, limit)
            return {
                ...value,
                items: newItems,
                loadmore: n > newItems.length
            }
        })
        this.setState({types: types, page: currentPage})
    }

    componentDidMount = () => {
        const me = this
        axios
            .get(apiUrl("workgroup.php"))
            .then(function(response) {
                if (response.status === 200) {
                    me.setState({data: response.data})
                    me.pagination(response.data)
                }
            })
            .catch(function(error) {
                console.log(error)
            })
    }

    render() {
        const {lang, history} = this.props
        const {data, types, perpage, page} = this.state
        if (!data) return null
        const {name_en, name_th, detail_en, detail_th} = data

        return (
            <React.Fragment>
                <main>
                    <div id="workgroup-page">
                        <div>
                            <div id="page-heading">
                                <h1>{lang === "en" ? name_en : name_th}</h1>
                                <p dangerouslySetInnerHTML={{__html: lang === "en" ? detail_en : detail_th}} className="description" />
                            </div>
                        </div>
                        <div>
                            <WorkList data={types} max={perpage * page} lang={lang} handleLoad={this.handleLoad} search={history.location.search} />
                        </div>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}

Workgroup.propTypes = {
    lang: PropTypes.oneOf(["en", "th"]).isRequired
}

export default Workgroup
