import React from "react"
import {Link} from "react-router-dom"
import axios from "axios"
import {Carousel} from "antd"
import {apiUrl} from "../../modules"
import "./workgroup.css"

class WorkgroupDetail extends React.Component {
    state = {data: false}

    componentDidMount = () => {
        const {
            match: {params}
        } = this.props
        const {id} = params
        const me = this
        axios
            .get(apiUrl("workgroup.php?id=" + id))
            .then(function(response) {
                if (response.status === 200) {
                    me.setState({data: response.data})
                }
            })
            .catch(function(error) {
                console.log(error)
            })
    }

    render() {
        const {data} = this.state
        const {lang} = this.props
        const {detail_en, detail_th, type_en, type_th, gallery, name_en, name_th} = data

        return (
            <div id="workgroup-detail">
                <div className="container">
                    <div id="page-heading">
                        <h1>{lang === "en" ? name_en : name_th}</h1>
                        <p className="workgroup-type">
                            <Link to={"/workgroup/?t=" + type_en}>{lang === "en" ? type_en : type_th}</Link>
                        </p>
                    </div>
                    {gallery && gallery.length > 0 && (
                        <div id="slider">
                            <Carousel autoplay draggable arrows>
                                {gallery.map((value, key) => {
                                    return (
                                        <div key={key}>
                                            <img src={"/img/upload/" + value.image} alt={value.image} />
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </div>
                    )}
                    <div id="detail">
                        <div dangerouslySetInnerHTML={{__html: lang === "en" ? detail_en : detail_th}} />
                    </div>
                </div>
            </div>
        )
    }
}

export default WorkgroupDetail
