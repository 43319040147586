import React from "react"
import PropTypes from "prop-types"
import {imgSrc} from "../../../modules"
import "./about.css"

class AboutComponent extends React.Component {
    render() {
        const {data, lang} = this.props
        if (!data || !data.about || !data.service) return null
        const {service, about} = data
        // const {name_en, name_th, detail_en, detail_th, logo} = about

        return (
            <div id="about">
                <div className="container">
                    <div>
                        <div>
                            <img src={imgSrc(about.logo)} alt={about.logo} />
                        </div>
                        <div>
                            <h2>{lang === "en" ? about.name_en : about.name_th}</h2>
                            <p dangerouslySetInnerHTML={{__html: lang === "en" ? about.detail_en : about.detail_th}} />
                        </div>
                    </div>

                    <div>
                        <div>
                            <h2>{lang === "en" ? service.name_en : service.name_th}</h2>
                            <p dangerouslySetInnerHTML={{__html: lang === "en" ? service.detail_en : service.detail_th}} />
                            <br/><br/>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{backgroundImage: "url('" + imgSrc(service.image) + "')"}} />
                    <div id="featured">
                        {service.items.map((value) => {
                            return (
                                <div key={value.id}>
                                    <img src={imgSrc(value.image)} alt={value.image} />
                                    <h3>{lang === "en" ? value.name_en : value.name_th}</h3>
                                    <p dangerouslySetInnerHTML={{__html: lang === "en" ? value.detail_en : value.detail_th}} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

AboutComponent.propTypes = {
    data: PropTypes.shape({
        about: PropTypes.shape({
            id: PropTypes.string,
            name_en: PropTypes.string,
            name_th: PropTypes.string,
            detail_en: PropTypes.string,
            detail_th: PropTypes.string,
            logo: PropTypes.string
        }).isRequired,
        service: PropTypes.shape({
            id: PropTypes.string,
            name_en: PropTypes.string,
            name_th: PropTypes.string,
            detail_en: PropTypes.string,
            detail_th: PropTypes.string,
            image: PropTypes.string,
            logo: PropTypes.string,
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string,
                    image: PropTypes.string,
                    name_en: PropTypes.string,
                    name_th: PropTypes.string,
                    detail_en: PropTypes.string,
                    detail_th: PropTypes.string
                })
            )
        }).isRequired
    }),
    lang: PropTypes.oneOf(["en", "th"]).isRequired
}

export default AboutComponent
